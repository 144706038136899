// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@stimulus/polyfills'
import 'custom-event-polyfill'

import { Application } from "stimulus"
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
application.load(definitionsFromContext(require.context("../controllers/common", true, /_controller\.js$/)))
application.load(definitionsFromContext(require.context("../controllers/portal", true, /_controller\.js$/)))
